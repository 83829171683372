<template>
  <v-row>
    <v-col cols="12">
      <v-switch :label="$t('active')" v-model="obCompany.active"></v-switch>
    </v-col>

    <firm-form v-model="obFirm" all-fields />
  </v-row>
</template>

<script lang="ts">
import { Component, VModel, Vue } from "vue-property-decorator";
import { Company, FirmData } from "@planetadeleste/vue-mc-gw";
import FirmForm from "@/modules/companies/components/FirmForm.vue";
import { LocationModule } from "@/store/location";

@Component({
  components: { FirmForm },
})
export default class CompaniesSettings extends Vue {
  @VModel({ type: Object, default: () => new Company() })
  obCompany!: Company;

  mounted() {
    this.$nextTick(() => {
      if (!this.obFirm.country_id && this.obCountry) {
        this.obFirm.country_id = this.obCountry.id;
      }
    });
  }

  get obFirm() {
    return this.obCompany.get("firm", {});
  }

  set obFirm(obData: Partial<FirmData>) {
    this.obCompany.set("firm", obData);
  }

  get obCountry() {
    return LocationModule.defaultCountry;
  }
}
</script>
